/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Container } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <footer className="footer px-0 px-lg-3">
        <Container fluid>
          <nav className="d-flex justify-content-center">
            <p style={{marginTop: '1rem'}}>Copyright ©2018 All Rights Reserved <a className="text-secondary" target="_blank" href="https://worldvoicegroup.com/"> WORLD VOICE GROUP</a>  </p>            
          </nav>
        </Container>
      </footer>
    );
  }
}

export default Footer;
