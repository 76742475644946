import React, { useState, useEffect } from "react";
import axios from "axios";
import NotificationAlert from "react-notification-alert";
import { useAuth } from "AuthProvider";
import { Form, Container, Row, Col, Button, Card, Badge } from "react-bootstrap";
import ApiConstants from "constant";

function Configuration() {
  const auth = useAuth();
  const [tauxCommissionnement, setTauxCommissionnement] = useState("");
  const [isOTPEnabledGA, setIsOTPEnabledGA] = useState(false); 
  const notificationAlertRef = React.useRef(null);

  useEffect(() => {
    const fetchTauxCommissionnement = async () => {
      try {
        const url = ApiConstants.baseUrlRequest + ApiConstants.getMarchand;
        const response = await axios.get(url, {
          params: {
            username: auth.user.username,
          },
        });
        if (response.status === 200) {
       
        
          setTauxCommissionnement(response.data.marchand.taux_commissionnement);
        } else {
         
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du taux de commissionnement:", error);
      }
    };

    fetchTauxCommissionnement();
  }, [auth.user.username]);


  const toggleOTPStatusGA = () => {
    setIsOTPEnabledGA(!isOTPEnabledGA); // Mise à jour de l'état
  };

  const handleSave = async () => {
    try {
      const url = ApiConstants.baseUrlRequest + ApiConstants.editMarchand;
      const data = {
        username: auth.user.username,
        taux_commissionnement: tauxCommissionnement,
      };
      const response = await axios.put(url, data);
      if (response.status === 200) {
        notify("success", "Taux de commissionnement enregistré avec succès !");
      } else {
       
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du taux de commissionnement:", error);
    }
  };

  const notify = (type, message) => {
    notificationAlertRef.current.notificationAlert({
      place: "tr",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      autoDismiss: 3,
      icon: "nc-icon nc-bell-55",
    });
  };

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Card>
          <Card.Header>
            <Card.Title as="h4">Taux de commissionnement en %</Card.Title>
          </Card.Header>
          <Card.Body>
            <Form>
              <Row>
                <Col md="12">
                  <Form.Group>
                    <Form.Control
                      placeholder="Entrez le taux de commissionnement par défaut"
                      type="text"
                      value={tauxCommissionnement}
                      onChange={(e) => setTauxCommissionnement(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col className="text-center" md="12">
                <Button className="btn-fill btn-wd" variant="success" size="sm" onClick={handleSave}>
                  Enregistrer
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* <Card>
          <Card.Header>
            <Card.Title as="h4">Configuration de la connexion par Google Authenticator(GA)</Card.Title>
          </Card.Header>
          <Card.Body>
            <p>
              Configurez les paramètres de la connexion par GA pour sécuriser l'accès à votre compte.
            </p>
            <Row>
              <Col md="6">
                <Form.Group>
                  <Form.Label>Statut de la connexion par GA</Form.Label>
                  {isOTPEnabledGA ? (
                    <Badge bg="success">Activé</Badge>
                  ) : (
                    <Badge bg="danger">Désactivé</Badge>
                  )}
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <Button
                    className="btn-fill btn-wd"
                    variant={isOTPEnabledGA ? "danger" : "success"}
                    size="sm"
                    onClick={toggleOTPStatusGA}
                  >
                    {isOTPEnabledGA ? "Désactiver" : "Activer"}
                  </Button>
                </Form.Group>
              </Col>
            </Row>
           
          </Card.Body>
        </Card>
         */}
       
      </Container>
    </>
  );
}

export default Configuration;
