import React, { useState } from "react";
import { BiPlus, BiDownload } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiOutlineDownload } from "react-icons/ai";
import {
  Badge,
  Button,
  Card,
  Modal,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Form,
  Table,
} from "react-bootstrap";
import Pagination from "components/utils/Pagination";
import { NavLink } from "react-router-dom";
import Error from "components/utils/Error";
import ApiConstants from "constant";
import axios from "axios";
import Loading from "components/utils/Loading";

import { useLocation, useHistory } from "react-router-dom";

import moment from "moment";
import "moment/locale/fr";
import { useAuth } from "AuthProvider";
moment.locale("fr");

function InfosAdresse() {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Container fluid>
        <Row>
          <Row>
            <Col
              sm="6"
              className="d-flex justify-content-left align-items-left"
            >
              <figure>
                <img
                  size={10}
                  className="cardLogoBig"
                  src={require("assets/img/logo.png")}
                  alt="..."
                />
              </figure>
            </Col>

            <Col
              sm="6"
              className="d-flex justify-content-end align-items-center"
            >
              <AiOutlineDownload size={40} />
            </Col>
          </Row>
          <Row>
            <Col
              md="12"
              className="d-flex justify-content-center align-items-center"
            >
              <div className="bg-success py-1 px-2 rounded">
                <h3 className=" text-white m-0 p-0">
                  {"Informations de l'adresse"}
                </h3>
              </div>
            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-between my-5">
            <Card>
              <Card.Body>
                <Row>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Noms et prénoms: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.adresse.client.name}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Email: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.adresse.client.email}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Pays: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.adresse.country}
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <br></br>
                <br></br>
                <Row>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Région: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.adresse.region}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Ville: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.adresse.town}
                      </div>
                    </div>
                  </Col>

                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Rue: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.adresse.street}
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <br></br>
                <br></br>
                <Row>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Code Postale: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.adresse.postal_code}
                      </div>
                    </div>
                  </Col>

                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Status: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.adresse.status}
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <br></br>
                <br></br>
                <Row></Row>
              </Card.Body>
            </Card>
          </Row>
          <Row className="d-flex justify-content-evenly">
            <Col sm="12" md="6" className=" d-flex justify-content-center ">
              <Button
                className="btn-fill"
                variant="primary"
                size="sm"
                onClick={(e) => {
                  e.preventDefault();

                  let url =
                    ApiConstants.baseUrlRequest + ApiConstants.updateAdresses;

                  const data = {
                    id_address: location.state.adresse.id,
                    status: "validé",
                  };

                  axios
                    .put(url, data)

                    .then(function (response) {
                      if (response.data.status == 200) {
                        let emailUrl =
                          ApiConstants.baseUrlRequest +
                          ApiConstants.setMailAdresses;

                        const datamail = {
                          id_adresse: location.state.adresse.id,
                          status: "validé",
                          code_client:
                            location.state.adresse.client.code_client,
                        };

                        axios
                          .post(emailUrl, datamail)
                          .then(function (response) {
                            if (response.data.status == 200) {
                              var message =
                                "Un mail a été envoyé avec succès à l'adresse du client.";

                              // Afficher une alerte avec le message
                              alert(message);
                              if (
                                location.pathname ===
                                "/welcome/adresseavalider/infos"
                              ) {
                                history.push({
                                  pathname: "/welcome/adresseavalider",
                                });
                              }
                              localStorage.removeItem("current_devis_created");
                            } else if (response.data.status == 404) {
                              setIsLoading(false);
                              setIsFound(false);
                            }
                          });
                      } else if (response.data.status == 404) {
                        setIsLoading(false);
                        setIsFound(false);
                      }
                    })
                    .catch(function (error) {
                      setIsError(true);
                      setError(error);
                    });
                }}
              >
                Accepter
              </Button>
            </Col>

            <Col m sm="12" md="6" className=" d-flex justify-content-center">
              <Button
                className="btn-fill"
                variant="danger"
                size="sm"
                onClick={(e) => {
                  e.preventDefault();

                  let url =
                    ApiConstants.baseUrlRequest + ApiConstants.updateAdresses;

                  const data = {
                    id_address: location.state.adresse.id,
                    status: "refusé",
                  };

                  axios
                    .put(url, data)
                    .then(function (response) {
                      if (response.data.status == 200) {
                        let emailUrl =
                          ApiConstants.baseUrlRequest +
                          ApiConstants.setMailAdresses;

                        const datamail = {
                          id_adresse: location.state.adresse.id,
                          status: "refusé",
                          code_client:
                            location.state.adresse.client.code_client,
                        };

                        axios
                          .post(emailUrl, datamail)
                          .then(function (response) {
                            if (response.data.status == 200) {
                              var message =
                                "Un mail a été envoyé avec succès à l'adresse du client.";

                              // Afficher une alerte avec le message
                              alert(message);
                              if (
                                location.pathname ===
                                "/welcome/adresseavalider/infos"
                              ) {
                                history.push({
                                  pathname: "/welcome/adresseavalider",
                                });
                              }
                              localStorage.removeItem("current_devis_created");
                            } else if (response.data.status == 404) {
                              setIsLoading(false);
                              setIsFound(false);
                            }
                          });
                      } else if (response.data.status == 404) {
                        setIsLoading(false);
                        setIsFound(false);
                      }
                    })
                    .catch(function (error) {
                      setIsError(true);
                      setError(error);
                    });
                }}
              >
                Refuser
              </Button>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
}

export default InfosAdresse;
