import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

export function PieChart({ data }) {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const myChartRef = chartRef.current.getContext('2d');

        chartInstance.current = new Chart(myChartRef, {
            type: 'pie',
            data: {
                labels: data.labels,
                datasets: [{
                    data: data.series,
                    backgroundColor: data.colors,
                    hoverOffset: 4
                }],
            },
            options: {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const label = context.label || '';
                                const value = context.parsed || 0;
                                const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
                                const percentage = value / total * 100;
                                return `${label}: ${percentage.toFixed(2)}%`;
                            }
                        }
                    },
                    legend: {
                        position: 'left',
                      },
                }
                
            }
        });

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [data]);


    return (
        <div style={{ width: '300px', height: '300px' }}>
            <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />
        </div>
    );
    
}
